import React, { Component } from 'react';
import styles from './style.module.css';


class Prices extends Component {
    constructor(props) {
        super(props);
        this.state = { width: 0, height: 0 };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }
    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }



    render() {

        return (
            <section id='prices'>
                <div className={styles.sectionWrapper}
                    style={{ minHeight: window.innerHeight }}>
                    <div className={styles.maxWidth}>
                        <div className={[styles.content, "animate", "animateBottom", "slowestTransition"].join(' ')}>

                            <div className={styles.contentWrapper + " revealClass"}>

                                <div className={styles.firstRow}>
                                    <div className={styles.cardPrice}>
                                        <div className={styles.title}>
                                            Damen
                                    </div>
                                        <div className={styles.prices}>
                                            <p>• Haarschnitt + Styling 90,- €</p>
                                            <p>• Cut & Go 80,- €</p>
                                            <p>• Styling 50,- €</p>
                                           
                                        </div>
                                    </div>
                                    <div className={styles.cardPrice}>
                                        <div className={styles.title}>
                                            Herren
                                   </div>
                                        <div className={styles.prices}>
                                            <p>• Haarschnitt + Styling 60,- €</p>
                                            <p>• Grauanteil pigmentieren ab 45,- €</p>
                                        </div>
                                    </div>
                                    <div className={styles.cardPrice}>
                                        <div className={styles.title}>
                                            Kinder
                                                     </div>
                                        <div className={styles.prices}>
                                            <p>• Haarschnitt 36,- €</p>
                                        </div>
                                    </div>
                                </div>

                                <div className={styles.secondRow}>
                                    <div className={styles.cardPrice}>
                                        <div className={styles.title}>
                                            Strähnen-Techniken
                                    </div>
                                        <div className={styles.prices}>
                                            <p>• Strähnen Oberkopf 100,- €</p>
                                            <p>• Strähnen ganzer Kopf 130,- €</p>
                                            <p>• Balayage Oberkopf inkl. Glossing 200,- €</p>
                                            <p>• Balayage ganzer Kopf inkl. Glossing 230,- €</p>
                                        </div>
                                    </div>
                                    <div className={styles.cardPrice}>
                                        <div className={styles.title}>
                                            Farbe
                                   </div>
                                        <div className={styles.prices}>
                                            <p>• Ansatzfarbe ab 75,- €</p>
                                            <p>• Neufärbung kurz/mittel ab 90,- €</p>
                                            <p>• Neufärbung lang ab 100,- €</p>
                                            <p>• Glossing ab 70,- €</p>
                                        </div>
                                    </div>

                                </div>



                                <div className={styles.thirdRow}>
                                    <div className={styles.cardPrice}>
                                        <div className={styles.title}>
                                            Pflege
                                    </div>
                                        <div className={styles.prices}>
                                            <p>• Intensivpflege 10,- €</p>
                                            <p>• Pflegebehandlung 15,- €</p>
                                            <p>• Aufbaukur aus 3 Schritten 39,- €</p>
                                        </div>
                                    </div>
                                    <div className={styles.cardPrice}>
                                        <div className={styles.title}>
                                            Kosmetik
                                   </div>
                                        <div className={styles.prices}>
                                            <p>• Augenbrauen färben 12,- €</p>
                                            <p>• Augenbrauen zupfen 10,- €</p>
                                            <p>• Wimpern färben 15,- €</p>
                                        </div>
                                    </div>

                                </div>


                            </div>


                            <div className={styles.horizontalRow}>
                                <div className={styles.prices}>

                                    <p className={styles.titleSemiBold}>Haarverdichtung oder Haarverlängerung mit Great Lengths Echthaarsträhnen
• Preis auf Anfrage kostenlose Beratung</p>

                                    <div>
                                        <p className={styles.title}>Keratinglättung</p> <p>ab 160,- €</p>
                                    </div>
                                </div>

                                <div className={styles.disclaim}>
                                    <p>  Die angegebenen Preise sind Grundpreise, die von einem durchschnittlichen Arbeitsaufwand ausgehen.
      Bei erhöhtem Aufwand erhalten Sie vorab einen entsprechenden Kostenvoranschlag.Bei Nichterscheinen oder Absagen kürzer als 24 Stunden vor dem Termin ist der volle Betrag des Ausfalls von den Kunden zu bezahlen.
                                </p>
                                </div>
                            </div>



                        </div>


                    </div>

                </div>

            </section>
        );
    }
}

export default Prices;
