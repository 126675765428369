import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Landing from './screens/Landing';

class App extends Component {
    render() {
        return (
            <div className="website-wrapper">
                <Router>
                    <Switch>
                        <Route exact path="/" component={Landing} />
                    </Switch>
                </Router>

            </div>
        );
    }
}

export default App;
