import PropTypes from 'prop-types';
import React from 'react';

const Wrapper = props => {
  return (
    <div className={props.className} style={Object.assign({}, props.style, { height: props.height, touchAction: 'auto', overflow: 'hidden' })}>
      {props.children}
    </div>
  );
};
export default class Slide extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    style: PropTypes.object,
    height: PropTypes.string
  };

  static defaultProps = {
    height: '100%',
    className: ''
  };

  render() {
    return (
      <Wrapper {...this.props} style={Object.assign({}, this.props.style, { height: this.props.height, touchAction: 'auto', overflow: 'hidden' })}>
        {this.props.children}
      </Wrapper>
    );
  }
}