import React, { Component } from 'react';
import styles from './style.module.css';
import Div100vh from 'react-div-100vh';
import DztImageGalleryComponent from 'reactjs-image-gallery'

import Img1 from "../../../../assets/1.jpg";
import Img2 from "../../../../assets/2.jpg";
import Img3 from "../../../../assets/3.jpg";
import Img4 from "../../../../assets/4.jpg";
import Img5 from "../../../../assets/5.jpg";
import Img6 from "../../../../assets/6-min.jpg";
import Img7 from "../../../../assets/7-min.jpg";
import Img8 from "../../../../assets/8-min.jpg";
import Img9 from "../../../../assets/9-min.jpg";
import Img10 from "../../../../assets/10-min.jpg";
import Img11 from "../../../../assets/11-min.jpg";
import Img12 from "../../../../assets/12-min.jpg";
import Img13 from "../../../../assets/13-min.jpg";
import Img14 from "../../../../assets/14-min.jpg";
import Img15 from "../../../../assets/15-min.jpg";
import Img16 from "../../../../assets/16-min.jpg";
import Img17 from "../../../../assets/17-min.jpg";
import Img18 from "../../../../assets/18-min.jpg";
import Img19 from "../../../../assets/19-min.jpg";
import Img20 from "../../../../assets/20-min.jpg";
import Img21 from "../../../../assets/21-min.jpg";
import Img22 from "../../../../assets/22-min.jpg";

import Lightbox from 'react-lightbox-component';


class Images extends Component {

    constructor(props) {
        super(props);
        this.state = { width: 0, height: 0 };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }
    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }


    render() {
        var data = [{
            url: Img1,
            thumbUrl: Img1,
        },
        {
            url: Img2,
            thumbUrl: Img2,
        },
        {
            url: Img3,
            thumbUrl: Img3,
        },
        {
            url: Img4,
            thumbUrl: Img4,
        },
        {
            url: Img5,
            thumbUrl: Img5,
        },
        {
            url: Img6,
            thumbUrl: Img6,
        },
        {
            url: Img7,
            thumbUrl: Img7,
        },
        {
            url: Img8,
            thumbUrl: Img8,
        },
        {
            url: Img9,
            thumbUrl: Img9,
        },
        {
            url: Img10,
            thumbUrl: Img10,
        },
        {
            url: Img11,
            thumbUrl: Img11,
        },
        {
            url: Img16,
            thumbUrl: Img16,
        },
        {
            url: Img17,
            thumbUrl: Img17,
        },
        {
            url: Img18,
            thumbUrl: Img18,
        },
        {
            url: Img19,
            thumbUrl: Img19,
        },
        {
            url: Img20,
            thumbUrl: Img20,
        },
            // {
            //     url: Img21,
            //     thumbUrl: Img21,
            // },
            // {
            //     url: Img22,
            //     thumbUrl: Img22,
            // },
            // {
            //     url: Img12,
            //     thumbUrl: Img12,
            // },
            // {
            //     url: Img13,
            //     thumbUrl: Img13,
            // },
            // {
            //     url: Img14,
            //     thumbUrl: Img14,
            // },
            // {
            //     url: Img15,
            //     thumbUrl: Img15,
            // },


        ];

        return (
            <sector id="gallery">

                <div className={styles.planWrapper}
                >
                    <div className={styles.sectionWrapper}
                        style={{ minHeight: window.innerHeight }}>

                        <div className={styles.contentWrapper + " revealClass"}>
                            <span className={styles.bigBlueTextSBold}>
                                Gallery

                                        </span>
                            <DztImageGalleryComponent
                                images={data} />

                        </div>


                    </div>


                </div >
            </sector>
        );
    }
}

export default Images;






