import React from "react";

export default ({ close }) => (
    <div className="modal" style={{}}>
        <p style={{
            color: "black", fontSize: 9, lineHeight: 2, paddingHorizontal: 20,

        }}>
            Datenschutzbestimmungen<br />

            Einführung<br />

            In diesen Datenschutzbestimmungen stellt Yonchev Friseure seine Vorgehensweise bezüglich der von Benutzern erfassten Daten, die auf unsere Webseite unter www.yonchev-friseure.de zugreifen oder uns auf andere Weise personenbezogene Daten bereitstellen (gemeinsam: „Benutzer“), dar.
                        <br /><br />


            Zuständige Behörde im Sinne der Datenschutz-Grundverordnung (DSGVO):
                        <br />
            Landesamt für Datenschutzaufsicht
                        <br />
            Postfach 606, 91511 Ansbach.
                        <br />
            Benutzerrechte

            Sie haben folgende Rechte:
                        <br />
            Eine Bestätigung, ob und inwieweit Ihre personenbezogenen Daten verwendet und verarbeitet werden, sowie den Zugriff auf die über Sie gespeicherten personenbezogenen Daten und zusätzliche Informationen anfordern
            Eine Kopie der personenbezogenen Daten, die Sie uns freiwillig bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format anfordern
            Eine Berichtigung der personenbezogenen Daten, die wir von Ihnen gespeichert haben, anfordern
            Das Löschen Ihrer personenbezogenen Daten beantragen
            Der Verarbeitung Ihrer personenbezogenen Daten durch uns widersprechen
            Die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten durch uns beantragen
            Eine Beschwerde bei einer Aufsichtsbehörde einreichen


            Beachten Sie jedoch, dass diese Rechte nicht uneingeschränkt gelten, sondern unseren eigenen rechtmäßigen Interessen sowie behördlichen Vorschriften unterliegen.

                        <br /><br />

            Wenn Sie eines der hier aufgeführten Rechte in Anspruch nehmen möchten oder weitere Informationen wünschen, wenden Sie sich an unseren Datenschutzbeauftragten unter:
                        <br />
            Evgeni Yonchev, Maistraße 21, 80337 München, 017666333602.<br />
            <br />
            Speicherung
                        <br />
            Wir speichern Ihre personenbezogenen Daten so lange, wie es für die Bereitstellung unserer Services, die Einhaltung rechtlicher Verpflichtungen sowie die Beilegung von Streitigkeiten und die Durchsetzung unserer Richtlinien erforderlich ist. Die Aufbewahrungsfristen richten sich nach der Art der erfassten Daten und dem Zweck, für den diese Daten erfasst wurden, wobei sowohl die fallspezifischen Gegebenheiten als auch die Notwendigkeit berücksichtigt werden, veraltete, nicht genutzte Informationen baldmöglichst zu löschen. Datensätze mit personenbezogenen Daten von Kunden, Dokumente über die Kontoeinrichtung, Mitteilungen und andere Daten speichern wir gemäß geltender Gesetze und Vorschriften.
                        <br />


            Wir können jederzeit und in unserem alleinigen Ermessen unvollständige oder unrichtige Daten korrigieren, vervollständigen oder entfernen.
                        <br /><br />
            Grundlage für die Datenerfassung
                        <br />
            Die Verarbeitung Ihrer personenbezogenen Daten (d. h. jegliche Daten, die mit vertretbaren Mitteln eine Identifizierung Ihrer Person zulassen; „personenbezogene Daten“) ist erforderlich, um unseren vertraglichen Verpflichtungen Ihnen gegenüber nachzukommen und damit wir Ihnen unsere Services bereitstellen, unser legitimes Interesse schützen sowie rechtlichen und finanziellen Regulierungsverpflichtungen, denen wir unterworfen sind, nachkommen können.
                        <br /><br />


            Durch die Nutzung dieser Webseite stimmen Sie der Erfassung, Speicherung, Verwendung, Offenlegung und sonstigen Nutzung Ihrer personenbezogenen Daten wie in diesen Datenschutzbestimmungen beschrieben zu.
                        <br />


            Bitte lesen Sie sich die Datenschutzbestimmungen sorgfältig durch, bevor Sie Entscheidungen treffen.
                        <br /><br />


            Welche Daten werden erfasst?
                        <br />


            Wir erfassen zwei Arten von Daten und Informationen von Benutzern.
                        <br />




            Zur ersten Kategorie gehören nicht identifizierende und nicht identifizierbare Benutzerdaten, die durch die Nutzung der Webseite bereitgestellt oder erfasst werden („Nicht personenbezogene Daten”). Wir kennen die Identität des Benutzers nicht, von dem nicht personenbezogene Daten erfasst wurden. Zu den nicht personenbezogenen Daten, die erfasst werden können, gehören aggregierte Nutzungsdaten und technische Daten, die von Ihrem Gerät übermittelt werden, einschließlich bestimmter Informationen bezüglich Software und Hardware (z. B. auf dem Gerät verwendeter Browser und verwendetes Betriebssystem, Spracheinstellung, Zugriffszeit usw.). Anhand dieser Daten verbessern wir die Funktionalität unserer Webseite. Wir können auch Daten über Ihre Aktivität auf der Webseite erfassen (z. B. aufgerufene Seiten, Surfverhalten, Klicks, Aktionen usw.).
                        <br />


            Zur zweiten Kategorie gehören personenbezogene Daten , also Daten, die eine Einzelperson identifizieren oder durch angemessene Maßnahmen identifizieren können. Zu solchen Daten gehören:
                        <br />
            Gerätedaten: Wir erfassen personenbezogene Daten von Ihrem Gerät. Solche Daten umfassen Geolocation-Daten, IP-Adresse, eindeutige Kennungen (z. B. MAC-Adresse und UUID) sowie andere Daten, die sich aus Ihrer Aktivität auf der Webseite ergeben.
                        Registrierungsdaten: Wenn Sie sich auf unserer Webseite registrieren, werden Sie um die Angabe bestimmter Informationen gebeten, z. B.: Vor- und Nachname, E-Mail-Adresse oder Anschrift bzw. andere Information.<br /><br />
            Wie erhalten wir Daten über Sie?
                        <br />
            Wir beziehen Ihre personenbezogenen Daten aus verschiedenen Quellen:
                        <br />
            Sie stellen uns solche Daten freiwillig bereit, zum Beispiel bei der Registrierung auf unserer Webseite.
            Wir erhalten solche Daten, wenn Sie unsere Webseite nutzen oder in Verbindung mit einem unserer Services darauf zugreifen.
            Wir erhalten solche Daten von anderen Anbietern, Services und aus öffentlichen Registern (zum Beispiel von Datentraffic-Analyseanbietern).
            Wie werden die Daten genutzt? An wen werden die Daten weitergegeben?
                        <br />
            Wir geben Benutzerdaten nicht an Dritte weiter, außer wie in diesen Datenschutzbestimmungen beschrieben.
                        <br /><br />


            Wir verwenden Daten für folgende Zwecke:
                        <br />
            Zur Kommunikation mit Ihnen (Senden von Hinweisen bezüglich unserer Services, Bereitstellen von technischen Informationen und ggf. Bearbeiten von Kundendienstanfragen)
            Zur Information über neue Updates und Services
            Zur Anzeigenschaltung im Rahmen der Nutzung unserer Webseite (weitere Informationen unter „Werbung“)
            Zur Vermarktung unserer Webseiten und Produkte (weitere Informationen unter „Marketing“)
            Zu statistischen und Analysezwecken, die der Verbesserung der Webseite dienen
            Neben den verschiedenen, oben aufgeführten Verwendungszwecken können wir personenbezogene Daten auch an unsere Tochtergesellschaften, verbundenen Unternehmen und Subunternehmer weitergeben.
                        <br />


            Zusätzlich zu den in diesen Datenschutzbestimmungen aufgeführten Zwecken können wir personenbezogene Daten aus folgenden Gründen an unsere vertrauenswürdigen externen Anbieter weiterleiten, die ihren Sitz in unterschiedlichen Rechtsprechungen auf der ganzen Welt haben:
                        <br />
            Hosten und Betreiben unserer Webseite<br />
            Bereitstellen unserer Services, einschließlich der personalisierten Anzeige unserer Webseite
            Speichern und Verarbeiten solcher Daten in unserem Namen
            Schalten von Anzeigen sowie die Möglichkeit, den Erfolg unserer Werbekampagnen zu beurteilen, Retargeting von Benutzern
            Bereitstellen von Marketingangeboten und Werbematerialien in Zusammenhang mit unserer Webseite und unseren Services
            Durchführen von Studien, technischen Diagnosen oder Analysen
            Wir können Daten auch offenlegen, wenn wir im guten Glauben sind, dies ist hilfreich oder angemessen, um: (i) geltenden Gesetzen, Vorschriften, Gerichtsverfahren oder behördlichen Anfragen zu entsprechen; (ii) unsere Richtlinien (einschließlich unserer Vereinbarung) durchzusetzen und ggf. diesbezügliche mögliche Verletzungen zu untersuchen; (iii) illegale Aktivitäten oder anderes Fehlverhalten, Betrugsverdacht oder Sicherheitsprobleme zu untersuchen, zu erkennen, zu verhindern oder Maßnahmen dagegen zu ergreifen; (iv) eigene Rechtsansprüche geltend zu machen oder durchzusetzen bzw. uns gegen die Ansprüche anderer zu verteidigen; (v) die Rechte, das Eigentum oder unsere Sicherheit, die Sicherheit unserer Benutzer, Ihre Sicherheit oder die Sicherheit von Dritten zu schützen; oder um (vi) mit Strafverfolgungsbehörden zusammenzuarbeiten und/oder geistiges Eigentum oder andere Rechtsansprüche zu schützen.
                        <br />
            Cookies
                        <br />
            Für die Bereitstellung entsprechender Services verwenden wir und unsere Partner Cookies. Dies gilt auch, wenn Sie unsere Webseite besuchen oder auf unsere Services zugreifen.
                        <br />


            Bei einem „Cookie“ handelt es sich um ein kleines Datenpaket, das Ihrem Gerät beim Besuch einer Webseite von dieser Webseite zugeordnet wird. Cookies sind nützlich und können für unterschiedliche Zwecke eingesetzt werden. Dazu gehören z. B. die erleichterte Navigation zwischen verschiedenen Seiten, die automatische Aktivierung bestimmter Funktionen, das Speichern Ihrer Einstellungen sowie ein optimierter Zugriff auf unsere Services. Die Verwendung von Cookies ermöglicht uns außerdem, Ihnen relevante, auf Ihre Interessen abgestimmte Werbung einzublenden und statistische Informationen zu Ihrer Nutzung unserer Services zu sammeln.

                        <br /><br />

            Diese Webseite verwendet folgende Arten von Cookies:
                        <br />


            a. „Sitzungscookies“ , die für eine normale Systemnutzung sorgen. Sitzungscookies werden nur für begrenzte Zeit während einer Sitzung gespeichert und von Ihrem Gerät gelöscht, sobald Sie Ihren Browser schließen.
                        <br />


            b. „Permanente Cookies“, die nur von der Webseite gelesen und beim Schließen des Browserfensters nicht gelöscht, sondern für eine bestimmte Dauer auf Ihrem Computer gespeichert werden. Diese Art von Cookie ermöglicht uns, Sie bei Ihrem nächsten Besuch zu identifizieren und beispielsweise Ihre Einstellungen zu speichern.
                        <br />


            c. „Drittanbieter-Cookies“ , die von anderen Online-Diensten gesetzt werden, die mit eigenen Inhalten auf der von Ihnen besuchten Seite vertreten sind. Dies können z. B. externe Web-Analytics-Unternehmen sein, die den Zugriff auf unsere Webseite erfassen und analysieren.

                        <br />

            Cookies enthalten keine personenbezogenen Daten, die Sie identifizieren, doch die von uns gespeicherten personenbezogenen Daten werden möglicherweise von uns mit den in den Cookies enthaltenen Daten verknüpft. Sie können Cookies über die Geräteeinstellungen Ihres Gerät entfernen. Folgen Sie dabei den entsprechenden Anweisungen. Beachten Sie, dass die Deaktivierung von Cookies zur Einschränkung bestimmter Funktionen bei der Nutzung unserer Webseite führen kann.
                        <br />


            Das von uns verwendete Tool basiert auf der Technologie von Snowplow Analytics . Zu den von uns erfassten Daten zur Nutzung unserer Webseite gehören beispielsweise, wie häufig Benutzer die Webseite besuchen oder welche Bereiche aufgerufen werden. Das von uns verwendete Tool erfasst keine personenbezogenen Daten und wird von unserem Webhosting-Anbieter und Service-Provider ausschließlich zur Verbesserung des eigenen Angebots verwendet.
                        <br /><br />
            Verwendung von Skriptbibliotheken (Google Web Fonts)
                        <br />
            Damit unsere Inhalte in jedem Browser korrekt und grafisch ansprechend dargestellt werden, verwenden wir für diese Webseite Skript- und Schriftbibliotheken wie Google Web Fonts (https://www.google.com/webfonts). Google Web Fonts werden in den Cache Ihres Browsers übertragen, sodass sie nur einmal geladen werden müssen. Wenn Ihr Browser Google Web Fonts nicht unterstützt oder den Zugriff verweigert, werden die Inhalte in einer Standardschriftart dargestellt.
                        <br />
            Beim Aufrufen von Skript- oder Schriftbibliotheken wir automatisch eine Verbindung zum Betreiber der Bibliothek hergestellt. Es besteht hierbei theoretisch die Möglichkeit für diesen Betreiber, Daten zu erfassen. Derzeit ist nicht bekannt, ob und zu welchem Zweck die Betreiber der entsprechenden Bibliotheken tatsächlich Daten erfassen.
            Hier finden Sie die Datenschutzbestimmungen des Betreibers der Google-Bibliothek: https://www.google.com/policies/privacy.
            Erfassung von Daten durch Dritte
                        <br />
            In dieser Richtlinie werden nur die Nutzung und Offenlegung von Daten, die wir von Ihnen erfassen, behandelt. Wenn Sie Daten auf anderen Webseiten veröffentlichen oder Dritten im Internet offenlegen, gelten möglicherweise andere Bestimmungen. Lesen Sie sich daher die allgemeinen Geschäftsbedingungen und Datenschutzbestimmungen immer sorgfältig durch, wenn Sie Daten offenlegen.
                        <br />


            Diese Datenschutzbestimmungen beziehen sich nicht auf Geschäftspraktiken von Unternehmen, die sich nicht in unserem Besitz befinden bzw. nicht unter unserer Kontrolle stehen, oder auf andere Personen als unsere Angestellten und Mitarbeiter, einschließlich Dritter, denen wir diese Daten wie in diesen Datenschutzbestimmungen beschrieben offenlegen.
                        <br /><br />
            Wie schützen wir Ihre Daten?
                        <br />
            Wir setzen die Sicherheitsmaßnahmen auf der Webseite mit großer Sorgfalt um und schützen Ihre Daten.Wir verwenden in der Branche übliche Verfahren und Richtlinien, um den Schutz der erfassten und gespeicherten Daten sicherzustellen, und verhindern die unbefugte Verwendung solcher Daten. Wir verlangen außerdem von Dritten, dass sie sich gemäß diesen Datenschutzbestimmungen an ähnliche Sicherheitsanforderungen halten. Obwohl wir angemessene Schritte für den Schutz von Daten unternehmen, können wir nicht verantwortlich gemacht werden für Handlungen von jenen, die sich unbefugten Zugang zu unserer Webseite verschafft haben oder diese missbräuchlich verwenden, und wir geben keine ausdrückliche noch stillschweigende Gewähr, dass wir einen solchen Zugriff verhindern können.
                        <br /><br />
            Übermittlung von Daten außerhalb des Europäischen Wirtschaftsraums
                        <br />
            Beachten Sie, dass einige Empfänger ihren Sitz möglicherweise nicht im Europäischen Wirtschaftsraum haben. Ist dies der Fall, werden wir Ihre Daten nur in von der Europäischen Kommission genehmigte Länder mit angemessenem Datenschutzniveau übermitteln oder durch eine rechtliche Vereinbarung ein angemessenes Datenschutzniveau sicherstellen.
                        <br />
            Werbung
                        <br />
            Wenn Sie auf unsere Webseite zugreifen, schalten wir möglicherweise Anzeigen mithilfe von Anzeigentechnologie externer Anbieter. Diese Technologie verwendet für die Anzeigenschaltung Ihre Nutzungsdaten der Services (z. B. durch die Platzierung von Drittanbieter-Cookies in Ihrem Webbrowser).
                        <br />


            Sie können sich von zahlreichen Anzeigennetzwerken Dritter abmelden, auch von Netzwerken, die von Mitgliedern der Network Advertising Initiative („NAI“) und der Digital Advertising Alliance („DAA“) betrieben werden. Informationen über die Vorgehensweise von NAI- und DAA-Mitgliedern, über Ihre Optionen, die Sie bezüglich der Verwendung solcher Daten von diesen Unternehmen haben, und darüber, wie Sie sich aus Anzeigennetzwerken Dritter, die von NAI- und DAA-Mitgliedern betrieben werden, abmelden können, finden Sie auf der jeweiligen Webseite: http://optout.networkadvertising.org/#!/ und http://optout.aboutads.info/#!/.
                        <br /><br />
            Marketing
                        <br />
            Wir können Ihre personenbezogenen Daten wie Ihren Namen, Ihre E-Mail-Adresse, Ihre Telefonnummer usw. selbst verwenden oder an einen externen Untervertragsnehmer weiterleiten, um Ihnen Werbematerialien bezüglich unserer Services bereitzustellen, die Sie möglicherweise interessieren.

                        <br />

            Wir respektieren Ihr Recht auf Privatsphäre. Daher erhalten Sie in diesen Marketingmaterialien stets die Möglichkeit, sich von weiteren Zusendungen abzumelden. Wenn Sie sich abmelden, wird Ihre E-Mail-Adresse oder Telefonnummer aus unseren Marketing-Verteilerlisten entfernt.

                        <br />

            Beachten Sie, dass wir Ihnen auch nach einer Abmeldung vom Erhalt unserer Marketing-E-Mails weiterhin E-Mails mit wichtigen Informationen senden, die keine Abmeldeoption enthalten. Dazu gehören Wartungsmeldungen oder administrative Benachrichtigungen.
                        <br /><br />
            Unternehmenstransaktion
                        <br />
            Wir können Daten im Fall einer Unternehmenstransaktion (z. B. beim Verkauf wesentlicher Unternehmensteile, bei einer Fusion, einer Konsolidierung oder einem Anlagenverkauf) weitergeben. Falls ein oben genannter Fall eintritt, übernimmt der Erwerber oder das entsprechende Unternehmen die in dieser Datenschutzerklärung dargelegten Rechte und Pflichten.
                        <br /><br />
            Minderjährige
                        <br />
            Der Schutz der Daten von Kindern ist insbesondere im Online-Bereich sehr wichtig. Die Webseite ist nicht für Kinder konzipiert und richtet sich nicht an diese. Die Nutzung unserer Services durch Minderjährige ist nur mit der vorherigen Einwilligung oder Autorisierung von einem Elternteil oder Erziehungsberechtigten zulässig. Wir erfassen personenbezogene Daten von Minderjährigen nicht wissentlich. Wenn ein Elternteil oder Erziehungsberechtigter Kenntnis davon erlangt, dass sein oder ihr Kind uns personenbezogene Daten ohne deren Einwilligung bereitgestellt hat, kann er/sie sich unter info@beautifybar.de an uns wenden.
                        <br /><br />
            Aktualisierungen oder Änderungen dieser Datenschutzbestimmungen
                        <br />
            Wir behalten uns das Recht vor, diese Datenschutzbestimmungen von Zeit zu Zeit zu ändern oder zu prüfen. Sie finden das Datum der aktuellen Version unter „Zuletzt geändert am“. Ihre fortgesetzte Nutzung der Plattform nach der Bekanntmachung solcher Änderungen auf unserer Webseite stellt Ihre Zustimmung zu solchen Änderungen an den Datenschutzbestimmungen dar und gilt als Ihr Einverständnis der Bindung an die geänderten Bestimmungen.
                        <br /><br />
            So erreichen Sie uns
                        <br />
            Wenden Sie sich bei allgemeinen Fragen zur Webseite, zu den von uns über Sie erfassten Daten oder der Verwendung dieser Daten unter evgeniyonchev@googlemail.com an uns.
                        <br /><br />


            Yonchev Friseure
                        <br />
            Inhaber: Evgeni Yonchev
                        <br />
            Stephansplatz 1
                        <br />
            80337 München
                        <br />
            017666333602
                        <br />
            www.yonchev-friseure.de
                        <br />
            evgeniyonchev@googlemail.com

                        <br /><br />

            Zuletzt geändert am 27.12.2019
                    </p>
    </div>
);