import FullPage from './components/FullPage';
import Slide from './components/Slide';
const ControlProvider = require('./components/ControlProvider');
const withControls = ControlProvider.withControls;

export {
  withControls,
  FullPage,
  Slide
};
