import React, { Component } from 'react';
import styles from './style.module.css';
import LB from '../../../../../assets/lb.jpg';
import GL from '../../../../../assets/gl.jpg';

// import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => <div style={{ backgroundColor: "black", height: 20, width: 20, borderRadius: 20 }}></div>;

class Contact extends Component {
    static defaultProps = {
        center: {
            lat: 48.13,
            lng: 11.56
        },
        zoom: 16
    };
    constructor(props) {
        super(props);
        this.state = { width: 0, height: 0 };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }
    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }


    render() {
        return (
            <section id='contact'>

                <div className={styles.sectionWrapper}
                    style={{ minHeight: window.innerHeight }}>
                    <div className={styles.maxWidth}>
                        <div className={styles.contentWrapper + " revealClass"}>
                            <div className={styles.working}>
                                <div className={styles.title}>Öffnungszeiten</div>
                                <span className={styles.data}>Montag - geschlossen</span>
                                <span className={styles.data}>Dienstag - 10:00 - 19:00</span>
                                <span className={styles.data}>Mittwoch - 10:00 - 19:00</span>
                                <span className={styles.data}>Donnerstag - 10:00 - 19:00</span>
                                <span className={styles.data}>Freitag -  10:00 - 19:00</span>
                                <span className={styles.data}>Samstag - 10:00 - 16:00</span>
                            </div>

                            <div className={styles.address}>
                                <div className={styles.title}>Kontakt</div>

                                <span className={styles.data}><a href="https://www.google.de/maps/place/YONCHEV+Friseure/@48.1309559,11.5669708,15z/data=!4m2!3m1!1s0x0:0x1e37dd661a7132f2?sa=X&ved=2ahUKEwjqsc_HyYfmAhV7wsQBHZFzC-AQ_BIwDHoECAUQCA" >Maistraße 21, 80337, München </a></span>

                                <span className={styles.data}><a href="tel:[017 666 333 602]">017 666 333 602</a></span>

                                <div className={styles.partner}>
                                    <div className={styles.title}>Partner</div>
                                    <img src={LB} style={{ height: 100, width: 220 }} />
                                    <img src={GL} style={{ height: 100, width: 220 }} />
                                </div>
                            </div>

                            <div>
                            </div>
                        </div>

                        {/* <div style={{ height: 500, width: "100%", marginTop: 70, borderRadius: 22.5, overflow: "hidden" }}>
                            <GoogleMapReact
                                bootstrapURLKeys={{ key: 'AIzaSyBXFtp27InfQ8bQmo3tPu6b3E4_5AKKAJU' }}
                                defaultCenter={this.props.center}
                                defaultZoom={this.props.zoom}
                            >
                                <AnyReactComponent
                                    lat={48.1309559}
                                    lng={11.5647768}
                                    text="My Marker"
                                />
                            </GoogleMapReact>
                        </div> */}
                    </div>
                </div >
            </section>
        );
    }
}

export default Contact;
