import React, { Component } from "react";
import styles from "./style.module.css";
import Header from "../../components/layout/Header";
import HeroWrapper from "../../components/Landing/Sections/HeroBanner";
import ProductPresentation from "../../components/Landing/Sections/ProductPresentation";
// import Carousel from "../../components/Landing/Sections/Carousel";
import Prices from "../../components/Landing/Sections/Carousel/Prices";
import Contact from "../../components/Landing/Sections/CarouselOffset/Contact";
import Product from "../../components/Landing/Sections/CarouselOffset/Product";
import Images from '../../components/Landing/Sections/Images';
import Daten from '../../components/Landing/Sections/Daten';
import UberMich from '../../components/Landing/Sections/UberMich';
import { Reveal } from "../../components/Common";

class Landing extends Component {
  componentDidMount() {
    window.addEventListener("scroll", this.handleReveal);
    this.handleReveal();
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleReveal);
  }
  handleReveal() {
    Reveal(".animate");
  }

  state = {
    headerVisible: false
  };


  render() {
    return (
      <div className={styles.landingWrapper}>
        <Header
          type={this.state.headerVisible ? "scrolledHeader" : "transparent"}
        />
        <HeroWrapper />
        <UberMich />
        <ProductPresentation />
        <Product />
        <Prices />

        <Contact />
        <Images />
        <Daten />
      </div>
    );
  }
}

export default Landing;
