import React, { Component } from 'react';
import styles from './style.module.css';
import AnimateHeight from 'react-animate-height';
class ProductPresentation extends Component {
    state = {
        height: 85,
        buttonColor: true
    };

    toggle = () => {
        const { height } = this.state;

        this.setState({
            height: height === 85 ? 'auto' : 85,
            buttonColor: !this.state.buttonColor
        });
    
    };
    goToPage = (pageNumber) => {
        this.reactPageScroller.goToPage(pageNumber);
    }

    // constructor(props) {
    //     super(props);
    //     this.state = { width: 0, minHeight: 0 };
    //     this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    // }
    // componentDidMount() {
    //     this.updateWindowDimensions();
    //     window.addEventListener('resize', this.updateWindowDimensions);
    // }

    // componentWillUnmount() {
    //     window.removeEventListener('resize', this.updateWindowDimensions);
    // }

    // updateWindowDimensions() {
    //     this.setState({ width: window.innerWidth, minHeight: window.innerHeight });
    // }



    render() {
        const { height } = this.state;
        let btnClass = this.state.buttonColor ? "learnMore" : "hideMore";

        return (
            <sector id="presentation">

                <div className={styles.sectionWrapper}
                    style={{ minHeight: window.innerHeight }}>
                    <div className={styles.maxWidth + " revealClass"}>
                        <div className={styles.contentWrapper + " revealClass"}>

                            <div className={styles.textContent}>
                                <div className={styles.textInner}>
                                    <div className={[styles.description, "sectionHeading", 'animate', 'animateTop'].join(' ')}>

                                        <span className={styles.bigBlueTextBold}>

                                            La Biosthétique
<br />
                                        </span>
                                        <span className={styles.bigBlueTextSBold}>
                                            TINT & TONE ADVANCED

                                        </span>
                                    </div>

                                    <div className={[styles.imageBiosM, "animate", "animateTop", "slowestTransition"].join(' ')}></div>

                                    <div className={[styles.innerText, "animate", "animateBottom", "slowestTransition"].join(' ')}>

                                        <AnimateHeight
                                            duration={500}
                                            height={height} // see props documentation below
                                        >
                                            <span className={styles.smallText}>
                                                Ein brillantes Farbsystem für pflegende Intensivtönungen und permanente Colorationen mit 99 Nuancen und bis zu 415 Farbmöglichkeiten.

                                        </span>
                                            <span className={styles.smallTextS}>
                                                TINT&TONE ADVANCED von La Biosthétique  ist eine Profi-Coloration in Spitzenqualität, deren Micro Pigment Technologie auf modernstem Forschungs- und Entwicklungsniveau basiert. Sie zeichnet sich vor allem durch exzellente Deckkraft, extrem lange Haltbarkeit, Nuancentreue und wunderschöne Brillanz aus. Das facettenreiche Nuancenspektrum lässt keinen Farbwunsch im Salon unerfüllt. Es umfasst viele trendgerechte Nuancen, darunter sensationelle Blondtöne mit sanften Pastell- oder zarten Irisé-Reflexen bis hin zu kühlem nordischem Blond.



Das Colorationssystem TINT & TONE ADVANCED steht für beste Farbqualität und verfügt zudem über ein Höchstmaß an innovativen Pflegestoffen: Japanischer Gelbwurzelextrakt und Aloe Vera wirken beruhigend und reizlindernd auf die Kopfhaut, während Betain hilft, Hautirritationen vorzubeugen. Beim Haar sorgen strukturstärkendes Glyzerin und feuchtigkeitsspendendes Kokosöl für seidige Geschmeidigkeit. Quaternäre Ammoniumverbindungen und Cocamidopropylbetain verstärken zusätzlich den Glanz und zaubern strahlende Farbbrillanz ins Haar.                                         </span>
                                        </AnimateHeight>
                                    </div>
                                </div>
                                <div className={[styles.innerPadding, "animate", "animateBottom", "slowestTransition"].join(' ')}>
                                    <div className={styles.btnClass} onClick={this.toggle.bind(this)}>
                                        {height === 85 ? 'Learn more' : 'Show less'}
                                    </div>
                                </div>
                            </div>
                            <div className={[styles.imageBios, "animate", "animateTop", "slowestTransition"].join(' ')}></div>

                        </div>

                    </div>
                </div >


            </sector>


        );
    }
}

export default ProductPresentation;
