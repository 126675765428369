import React, { Component } from 'react';
import styles from './style.module.css';
import Div100vh from 'react-div-100vh';

import Lightbox from 'react-lightbox-component';


class UberMich extends Component {



    render() {
        return (
            <sector id="ubermich">

                <div className={styles.sectionWrapper}
                    style={{ minHeight: window.innerHeight }}>
                    <div className={styles.maxWidth + " revealClass"}>
                        <div className={styles.contentWrapper + " revealClass"}>

                            <div className={styles.textContent}>
                                <div className={styles.textInner}>
                                    <div className={[styles.description, "sectionHeading", 'animate', 'animateTop'].join(' ')}>

                                        <span className={styles.bigBlueTextBold}>

                                            Wer bin ich?
<br />
                                        </span>
                                        <span className={styles.bigBlueTextSBold}>
                                            Ich bin Evgeni.

                                        </span>
                                    </div>

                                    <span className={styles.smallText}>
                                        Haare haben mich immer faszierniert. 2012 machte ich meine Leidenschaft
        zu meinem Beruf. Mein besonderes Interesse gilt der Farbe und Extensions.
        Diese Schwerpunkte habe ich immer wieder perfektioniert, jede neue Entwicklung
        verfolgt und ausprobiert. Zudem hatte ich das Glück meine Kreativität bei Farben
        und Schnitten bei Events präsentieren zu können.
        2017 erreichte ich ein weiteres Ziel : Ich erwarb den Meistertitel.
        Und nun habe ich meinen ganz großen Traum verwirklicht:
        Mein eigenes Geschäft.
        An einem zauberhaften kleinen Platz im Glockenbachviertel, kann ich meine
        Begeisterung, Kreativität und Freude an Haaren verwirklichen.
                                        <br />
                                        Haare sollen faszinieren, sollen bewundert werden, sollen inspirieren.
                                        Haare sind ein Statement.
                                        Haare zeigen Stil.
                                        Haare sind ein Markenzeichen.
                                        Haare sind Persönlichkeit.
                                                        <br />
                                        Alles das würde ich gerne für Sie und mit Ihnen kreieren.
                                                        <br />
                                        Ihr Evgeni
                                        </span>


                                    <div className={[styles.imageBiosM, "animate", "animateTop", "slowestTransition"].join(' ')}></div>

                                    <div className={[styles.innerText, "animate", "animateBottom", "slowestTransition"].join(' ')}>


                                    </div>
                                </div>

                            </div>
                            <div className={[styles.imageBios, "animate", "animateTop", "slowestTransition"].join(' ')}></div>

                        </div>

                    </div>
                </div >


            </sector>





        );
    }
}

export default UberMich;







