import React, { Component, Text } from "react";
import { Slide } from '../../../../lib/react-full-snap';
import styles from "./style.module.css";
import logo from '../../../../assets/images/logo-header-white';
import { Link } from 'react-router-dom';
console.log('---Slide', Slide);

class HeroWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  render() {
    return (
      <sector id="home">
        <div
          className={styles.heroWrapper}
          ref={this.container}
          style={{
            minHeight: window.innerHeight,
          }}>
          <div className={[styles.textContent, "animate", "animateTop"].join(" ")}>
            <div className={[styles.innerContentHeading].join(" ")}>
              <div className={styles.heading}>

                <div className={styles.logoContainer}>
                  <div className={styles.logoInnerAbstract}></div>
                  <Link to="/">{logo}</Link>
                </div>

              </div>

            </div>
          </div>
        </div>

      </sector>
    );
  }
}

export default HeroWrapper;
