import React, { Component } from 'react';
import styles from './style.module.css';
import Div100vh from 'react-div-100vh';
import Popup from "reactjs-popup";
import Content from "./content.js";
import "./index.css";

import Lightbox from 'react-lightbox-component';


class Daten extends Component {



    render() {
        return (
            <div className={styles.planWrapper}>

                <Popup modal trigger={<button style={{
                    width: "100%", background: "#2e2e2e",
                    color: "#fff",
                    fontSize: 12,
                    maxHeight: 300,
                }}>Datenschutz/Impressum</button>}>

                    {close => <Content close={close} />}
                </Popup>
            </div >

        );
    }
}

export default Daten;







