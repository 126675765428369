let timeout;
export const debounce = (func, wait, immediate) => {
    var timeout;
    return function () {
        var context = this, args = arguments;
        var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};

const isElementInViewport = (el) => {
    var rect = el.getBoundingClientRect();
    return rect.bottom > 0 &&
        rect.right > 0 &&
        rect.left < (window.innerWidth || document.documentElement.clientWidth) &&
        rect.top < (window.innerHeight || document.documentElement.clientHeight)
}

export const Reveal = (selector) => {
    if (!timeout) {
        timeout = setTimeout(function () {
            // Reset timeout
            timeout = null;
            const elements = document.querySelectorAll(selector);
            elements.forEach(x => {
                if (isElementInViewport(x)) {
                    x.classList.add('visible');
                }
                else {
                    x.classList.remove('visible');
                }
            })
        }, 35);
    }
}

export const SmoothScrollToTop = () => {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'auto'
        // behavior: 'smooth'
    });
}
