import React, { Component } from 'react';
import styles from './style.module.css';

class Product extends Component {
    state = {
        height: 115,
        buttonColor: true
    };

    toggle = () => {
        const { height } = this.state;

        this.setState({
            height: height === 115 ? 'auto' : 115,
            buttonColor: !this.state.buttonColor
        });
    };
    render() {
        const { height } = this.state;
        let btnClass = this.state.buttonColor ? "learnMore" : "hideMore";

        return (
            <sector id="services">
                <div className={styles.sectionWrapper}>
                    <div className={styles.maxWidth}>
                        <div className={styles.contentWrapper + " revealClass"}>
                            <div className={styles.textContent}>

                                <div className={[styles.cardHolder, "sectionHeading", 'animate', 'animateTop'].join(' ')}>
                                    <div className={styles.imgStyling}></div>
                                    <div className={styles.cardProduct}>
                                        <div className={[styles.description, "", ""].join(' ')}>
                                            <span className={styles.bigBlueText}>Styling</span></div>
                                        <span className={styles.smallText}>Die La Biosthétique Styling-Produkte präsentieren sich gemäß der logischen Anwendungsreihenfolge vom feuchten bis zum trockenen und geföhntem Haar in drei optisch unterschiedlichen Gruppen: Base (Graphite), Style (Champagner) und Finish (Lilac)
</span>
                                    </div>
                                </div>


                                <div className={[styles.cardHolder, "sectionHeading", 'animate', 'animateTop'].join(' ')}>
                                    <div className={styles.imgPflege}></div>
                                    <div className={styles.cardProduct}>
                                        <div className={[styles.description, "", ""].join(' ')}>
                                            <span className={styles.bigBlueText}>Pflege</span></div>
                                        <span className={styles.smallText}>Die Haare sind der erste Schmuck des Menschen, Ausdruck von Persönlichkeit, Stil und Empfinden. Schöne, gesunde Haare beginnen mit einer gesunden Kopfhaut, und seit dem Tag der Gründung von La Biosthétique beschäftigen diese Zusammenhänge unsere Wissenschaftler. Denn die besten Ergebnisse bekommt nur, wer das Beste leistet.
</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div >
            </sector>
        );
    }
}

export default Product;
